.App {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
}

.details {
  max-width: 90% !important;
  margin: 0 auto;
}

.layout {
  min-height: 100vh;
}
