.banner-component{
    margin:0;
    padding:0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 768px) {
        height:70vh;
        background: white;
       
    }
    .small-logo{
        margin-bottom:10px;
        width:150px;
        display: inline-block;
    }
    .banner-content{
        width:40%;
        z-index: 10;
        height: 100;
        padding:0 40px;
        padding-top:40px;
        @media only screen and (max-width: 768px) {
            margin-top:-50px;
            width:99%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            > * {
                color:white !important;
                border-color:white !important;
            }
            
         }
       
        .known-for{
            border-bottom:2px solid #6f6f6f;
            text-transform: uppercase;
            letter-spacing: 2px;
            padding-bottom:5px;
            font-size: 11.9px;
            color: #6f6f6f;
            width:fit-content;
        }
        h1{
            margin:0;
            margin-top:0;
            margin-bottom:30px;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: 4px;
            font-family: 'Source Sans Pro';
            font-size: calc(15px + 2.5vw);
            color:#454545;
            @media only screen and (max-width: 1200px) {
                font-size: calc(24px + 1vw);
                
            }
            @media only screen and (max-width: 900px) {
                font-size: calc(14px + 1.5vw);
                
            }
            .gray{
                font-weight: 700;
                font-style: italic;
                color: #afb1b3;
            }
            .blue{
                font-weight: 700;
                font-style: italic;
                color: #1572a5;
            }
        }
        p{
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-weight: 400;
            font-size: calc(15px + 0.3vw);
            line-height: 2;
            display: flex;
            align-items: center;
            color: #6f6f6f;
            margin:0;
            @media only screen and (max-width: 900px) {
                line-height: 1.6;
                font-size: calc(15px + 0.3vw);
                
            }
           
        }
        button{
            width:fit-content;
            background-color: rgba(255, 255, 255, 0.957);
            border: 2px solid #9b9b9b !important;
            color:#454545;
            padding:13px 27px;
            letter-spacing: 1px;
            text-transform: uppercase;
            // color: #002848 !important;
            display: flex;
            align-items: center;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            @media only screen and (max-width: 768px) {
                border:0 !important;
            }
        }
    }

    video{
        max-width:100%;
    }
    .banner-image{
        background:black;
        width:70%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        clip-path: polygon(100% 0, 100% 33%, 92% 92%, 0 87%, 0 0);
        height:105vh;
        position: relative;
        #myVideo {
            position: absolute;
            right: 0;
            bottom: 0;
            object-fit: cover;
            width: 100%;
            clip-path: polygon(100% 0, 100% 33%, 92% 92%, 0 87%, 0 0);
            height: 100%;
          }
          .footer-logo{
            
            filter: brightness(1.5);
            z-index:10000;
            position:relative;
            cursor:pointer;
            width:200px;
            margin-bottom:calc(20px + 8vw);
            
            @media only screen and (max-width: 768px) {
                display: none;
                width:270px;
            }
            @media only screen and (max-width: 1136px) {
                // display: none;
                width:270px;
                margin-bottom: 140px !important;
            }
        }
        @media only screen and (max-width: 768px) {
            @media only screen and (max-width: 768px) {
                width:100%;
                position: absolute;
                height:90%;
                margin-top:-80px;
                clip-path: polygon(100% 0, 100% 78%, 95% 92%, 0 100%, 0 0);
    
                #myVideo {
                    clip-path: polygon(100% 0, 100% 78%, 95% 92%, 0 100%, 0 0);
                    filter: brightness(0.45);
                }
                
            }
            
        }
        .play-btn{
            cursor: pointer;
            position: absolute;
            z-index: 999;
            top:0;
            bottom:130px;
            left:0;
            right:0;
            margin:auto;
            height:190px;
            width:190px;
            padding:40px;
            object-fit: contain;
           
         
        }
      
    }
}
.modal-body {
  padding:0;
  margin:0;
  margin-bottom:-10px;
  video{
    border-radius:7px;
  }
}