.dropzone {
  margin-bottom:20px;
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    height:100px;
    font-size:12px;
    display: flex;
    // flex-direction: column;
    color:#848484;
    justify-content:center;
    align-items: center;
    li{
      list-style: none;
    }
    svg{
      height:60px;
      width:60px;
      margin-right:20px;
      path{
        fill:#e3e3e3;
      }
    }
  }
  
  .dropzone.active {
    background-color: #f7f7f7;
  }
  
  .dropzone p {
    margin: 0;
  }
  
  .dropzone:hover {
    background-color: #f8f8f8;
  }
  aside{
    display: flex;
  }
  
  .dropzone input, .dropzone button {
    display: none;
  }