.home-content-component{
    @media only screen and (max-width: 768px) {
       margin-top:50px;
       
    }
   
    .dots-bottom{
        position:absolute;
        bottom:-10%;
        right:-10%;
    }
    .dots-top{
        position:absolute;
        top:-10%;
        left:-20%;
    }
    .icon{
        width:fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding:5px;
        border:3px solid #9e9e9e;
        border-radius:50%;
        margin-right:20px;
        svg{
            path{
                fill:#9e9e9e !important;
            }
        }
   
    }
    
    .checklist-container{
        margin:0 auto;
        display:flex;
        max-width:1200px;
        color:#9e9e9e;
        font-weight: 600;
        text-align: left !important;
        flex-direction: row !important;
        font-size: calc(10px + 0.3vw) !important;
        @media only screen and (max-width: 768px) {
            flex-direction: column !important;
           
        }

       .checklist-1, .checklist-2{
            display: flex;
            flex-direction: column;
           
           
            .checklist{
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                padding:10px;
                @media only screen and (max-width: 768px) {
                    padding:10px 0;
                }
                &:hover{
                    span{
                        font-size:16px;
                        color:#1c7cd7;
                        line-height:1.2;
                        transition: all 0.5s ease-in-out;
                        &:before{
                            content: "";
                            position: absolute;
                            bottom:-3px;    
                            left:-3px;
                            height:2px;
                            width:35px;
                            transition: all 0.5s ease-in-out; 
                            background-color: #1c7cd7;     
                        }
                    }
                }
                span{
                    line-height:1.5;
                    position: relative;
                    transition: all 0.5s ease-in-out;
                    &:before{
                        transition: all 0.5s ease-in-out; 
                        content: "";
                        position: absolute;
                        bottom:-3px;
                        width:0%;
                        left:-3px;
                        background-color: #1c7cd7;
            
                    }
                  
                }
                
               
            }
            &.checklist-1{
             
                border-right:10px solid #9e9e9e;
                margin-left: 30px;
                
                @media only screen and (max-width: 768px) {
                    margin-left:0 !important;
                    border:0;
                    padding:0 20px;
                }
            }
            &.checklist-2{
                padding-left:10px;
                @media only screen and (max-width: 768px) {
                    border:0;
                    padding:0 20px;
                }
            }
        }
    }
    .details {
       
        display: flex;
        z-index: 1000;
        color:#9e9e9e;
        min-width:200px;
        font-weight: 600;
        z-index:10;
        width:fit-content;
        // width:fit-content;
        // position: relative;
        margin-bottom:40px;
        text-align: center;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: transparent;
        font-size: calc(10px + 0.6vw);
        position: relative;
        .dots-bottom{
            position:absolute;
            z-index:10;
            bottom:-30%;
            right:-20%;
        }
        .circle{
            color:#1c7cd7;
            margin:2px 5px;
        }
        .dots-top{
            position:absolute;
            z-index:10;
            top:-30%;
            left:-30%;
           
        }
    
        h1{
            background-color: white;
            min-width:200px;
            display: flex;
            align-items: center;
            justify-content: center;
            width:fit-content;
            padding:20px;
            box-shadow: rgba(160, 160, 160, 0.202) 0px 4px 40px;   
            margin:0;
            color: #1c7cd7;
            font-weight: 700;
            line-height: 1.5;
            text-align: center;
            letter-spacing: 0.145em;
            text-transform: uppercase;
            font-family: 'Source Sans Pro';
            font-size: calc(10px + 2.3vw);
            margin-left:-30px;
            z-index:1000;
            position: relative;
            .heading-content{
                height:100%;
                width:100%;
                background: white !important;
                z-index:1000000;
             
            }
           
        }
    }
    button{
        width:fit-content;
        background-color: white;
        border: 2px solid #9b9b9b;
        padding:13px 27px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #454545;
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        @media only screen and (max-width: 768px) {
            padding:8px;
            font-size: 12px;
        }

    }
    h3{
        font-family: 'Source Sans Pro';
        font-weight: 700;
        font-size: calc(10px + 2.4vw);
        letter-spacing: 0.145em;
        text-transform: uppercase;
        color: #454545;
        @media only screen and (max-width: 500px) {
           margin:0;
         }
    }
    .description{
        margin-bottom:10px;
        font-weight: 600;
        font-size: calc(4px + 0.9vw);
        line-height: 1.8;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        color:#606060;
        @media only screen and (max-width: 768px) {
           margin:0px 0 10px;
        }
        .desc-container{
            width:100%;
            margin-top:40px;
            display: flex;
            align-items: center;
            span{
                color:#1c7cd7;
                font-weight: 700;
                font-size: calc(30px + 2.5vw);
                margin-right:20px;
            }
            ul{
                margin:0;
                font-weight: 500;
                color:#828282;
                font-size: calc(4px + 0.7vw);
            }
        }
    }
    .specs{
        width:100%;
        margin-top:140px;
        @media only screen and (max-width: 768px) {
            margin-top:70px
        }
        .spec-content{
            width:48%;
            padding:30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            @media only screen and (max-width: 768px) {
               padding:0;
               width:100%;
            
            }
        }
        .spec-container{
            padding:30px 5vw;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media only screen and (max-width: 768px) {
                width:100%;
                flex-direction: column;
              .spec-image{
                margin-top:50px;
                width:90% !important 
            }
            }
            .spec-image{
                position: relative;
                width:48%;
                img{
                    position:relative;
                    z-index:100;
                    width: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    position: relative;
                    
                    
                }
                .image{
                    position: relative;
                    &:before{
                        z-index: 1000;
                        content:"";
                        position: absolute;
                        right:-20px;
                        width:20px;
                        height:80%;
                        bottom:0px;
                        background-color: #afb1b3;
                    }
                    &:after{
                        z-index: 1000;
                        content:"";
                        position: absolute;
                        right:-20px;
                        height:20px;
                        width:80%;
                        bottom:-20px;
                        background-color: #afb1b3;
                    }
                }
            }
            &:nth-child(2n){
                flex-direction: row-reverse;
            }
        }
    }
    
}